<template>
  <section>

    <BrunaForm
      ref="demandDetailRef"
      v-bind:fields="this.demand_detail.fields"
      :card="this.demand_detail.card"
      @change="changeForm"
    ></BrunaForm>

    <b-row>
      <b-col>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button>
      </b-col>
    </b-row>

  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaFormRepeat from "@/views/brunacomponents/BrunaFormRepeat.vue";

import Vue from 'vue'
import router from '@/router'

import
{
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';


import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null
    }
  },

  data()
  {

    return {

      demand_detail: {
        card: {
          title: "Ingresar nueva demanda",
          subtitle: "",
          tooltip: "",          
          sidebarContent: {
            title: '',
            body: "Solo ingresar puntos para delimitar decimales"
          }
        },
        fields: [
          {
            label: "CANAL",
            type: "select",
            id: "canal",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "FOODSERVICE", text: "01: Foodservice" },
              { value: "SUPERMERCADO", text: "02: Supermercado" },
              { value: "TRADICIONAL", text: "03: Tradicional" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "GRUPO",
            type: "input",
            id: "grupo",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "CADENA",
            type: "input",
            id: "cadena",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "PROCODIGO",
            type: "select",
            id: "procodigo",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "X6000", text: "X6000" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "PRODUCTO",
            type: "select",
            id: "producto",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "SALCHICHA SUREÑA 6 X 1000 [G.]", text: "SALCHICHA SUREÑA 6 X 1000 [G.]" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "DEMANDA (kg)",
            type: "input",
            placeholder:"1000",
            id: 'demanda',
            rules: 'required|numeric',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "SEMANA",
            type: "select",
            id: "semana",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "35", text: "35 (30 agosto al 5 de septiembre)" },
              { value: "36", text: "36 (06 al 12 de septiembre)" },
              { value: "37", text: "37 (13 al 19 de septiembre)" },
              { value: "38", text: "38 (20 al 26 de septiembre)" },
              { value: "39", text: "39 (27 de septiembre al 3 de octubre)" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "AÑO",
            type: "select",
            id: "anho",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "2020", text: "2020" },
              { value: "2021", text: "2021" },
              { value: "2022", text: "2022" },
              { value: "2023", text: "2023" },
              { value: "2024", text: "2024" },
              { value: "2025", text: "2025" },
              { value: "2026", text: "2026" },
              { value: "2027", text: "2027" },
              { value: "2028", text: "2028" },
              { value: "2029", text: "2029" },
              { value: "2030", text: "2030" },
              { value: "2031", text: "2031" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "PRECIO PROMEDIO POR KILO (CLP)",
            type: "input",
            placeholder:"1000",
            id: 'precio',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
        ]
      },


    };
  },
  components: {

    ToastificationContent,
    vSelect,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
    BrunaFormRepeat
  },

  created()
  {


  },

  mounted()
  {

  },

  methods: {

    formatData()
    {

      return {
        demand_detail: this.demand_detail.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      }


    },
    createContrato()
    {
      useApiServices.postContratosCreate(this.formatData(), this)
        .then((response) =>
        {
          // console.log(response);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Carga Exitosa`,
                icon: "UploadIcon",
                variant: "success",
                html: `La demanda se ha creado exitosamente.`,
              },
            });

            router.push({ path: `/pf/historico-demanda` });
        })
        .catch((error) =>
        {
          console.log(error);
            this.$toast({
            component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Ocurrio un Error`,
                icon: "UploadIcon",
                variant: "danger",
                html: `Ha ocurrido un error al guardar la demanda</b>.`,
              },
            });
          
        });
    },

    save()
    {
      // console.log(JSON.stringify(this.formatData(), null, 2))
      
      let self = this;
      Promise.all([
        this.$refs.demandDetailRef.validate(),
      ]).then(([demandDetailValid]) =>
      {
        if (demandDetailValid )
        {
          this.createContrato();
        }
      })
    },

    resetForm() {

      // console.log(this.$refs.demandDetailRef);
      // this.$refs.demandDetailRef.resetForm();
      // this.findFieldByName(this.demand_detail.fields, "canal").value = "";
      // this.findFieldByName(this.demand_detail.fields, "grupo").value = "";
      // this.findFieldByName(this.demand_detail.fields, "cadena").value = "";
      // this.findFieldByName(this.demand_detail.fields, "procodigo").value = "";
      // this.findFieldByName(this.demand_detail.fields, "producto").value = "";
      // this.findFieldByName(this.demand_detail.fields, "demanda").value = "";
      // this.findFieldByName(this.demand_detail.fields, "semana").value = "";
      // this.findFieldByName(this.demand_detail.fields, "anho").value = "";
      // this.findFieldByName(this.demand_detail.fields, "precio").value = "";
    },

    changeForm() {
      return
    },

    findFieldByName(fields, name)
    {
      return fields.find(field => field.id == name)
    },
  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>