import { render, staticRenderFns } from "./IngresoDemanda.vue?vue&type=template&id=c5fec22c&scoped=true&"
import script from "./IngresoDemanda.vue?vue&type=script&lang=js&"
export * from "./IngresoDemanda.vue?vue&type=script&lang=js&"
import style0 from "./IngresoDemanda.vue?vue&type=style&index=0&id=c5fec22c&prod&scoped=true&lang=css&"
import style1 from "./IngresoDemanda.vue?vue&type=style&index=1&id=c5fec22c&prod&lang=scss&"
import style2 from "./IngresoDemanda.vue?vue&type=style&index=2&id=c5fec22c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5fec22c",
  null
  
)

export default component.exports